<script>
  export let Text = "Default Title";
  export let text = "Default Description";
</script>

<div class="sample-component">
  <h1>{Text}</h1>
  <p>{text}</p>
</div>

<style>
  .sample-component {
    /* styles */
  }
</style>
